
















































































































































































































































































import Vue from 'vue';
import TldrMessage from '@/components/legal/TldrMessage.vue';

export default Vue.extend({
  components: {
    TldrMessage,
  },
  props: {
    isTableOfContents: Boolean,
    bottomAnchor: {
      type: String,
      required: false,
      default: null,
    },
  },
});
